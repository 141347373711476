import styled from 'styled-components'
import Button from '@/components/Button'

const desktopContainerDimension = '1148px' // Container max-width

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 30px;
  width: fit-content;
  margin: 0 auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    text-align: initial;
    max-width: unset;
  }

  @media screen and (min-width: ${desktopContainerDimension}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const SecondaryButton = styled(Button).attrs({ variant: 'secondary' })`
  display: flex !important;
  padding: 0 16px;
  margin: auto;
  margin-top: 50px;
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: 60px;
  }
`
