import { isProduction } from "./env.utils";

const INTROSPECTION_PATTERNS = ["__schema", "__type"];

/**
 * Checks if a GraphQL query is attempting introspection
 */
export const isIntrospectionQuery = (
	body: Record<string, unknown>,
): boolean => {
	if (!isProduction()) return false;
	if (!body || typeof body !== "object") return false;

	const query = body.query || "";
	if (typeof query !== "string") return false;

	return INTROSPECTION_PATTERNS.some((pattern) => query.includes(pattern));
};

// Patterns that indicate field suggestions in error messages
const FIELD_SUGGESTION_PATTERNS = [
	"Did you mean",
	"did you mean",
	"Available options are",
	"available options are",
	"Unknown field",
	"unknown field",
	"Cannot query field",
];

// Sanitize error messages to remove field suggestions
export const sanitizeErrorMessages = (data: any): any => {
	if (!data || !data.errors || !Array.isArray(data.errors)) {
		return data;
	}

	// Only sanitize in production
	if (!isProduction()) {
		return data;
	}

	// Create a deep copy to avoid modifying the original
	const sanitizedData = JSON.parse(JSON.stringify(data));

	// Sanitize each error message
	sanitizedData.errors = sanitizedData.errors.map((error: any) => {
		if (error.message) {
			// Check if the error message contains field suggestions
			const containsSuggestion = FIELD_SUGGESTION_PATTERNS.some((pattern) =>
				error.message.includes(pattern),
			);

			if (containsSuggestion) {
				// Replace with a generic error message
				return {
					...error,
					message: "Invalid field or argument",
					extensions: {
						...(error.extensions || {}),
						code: "INVALID_FIELD",
					},
				};
			}
		}
		return error;
	});

	return sanitizedData;
};
