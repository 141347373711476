import styled, { css } from 'styled-components'
import Container from '@/components/Container'
import { HeroImageWrapper } from '../../components/HeroImage'
import SectionWrapper from '@/components/SectionWrapper'
import { styledText } from '@/components/Typography/Typography.styles'
import { TextStyles } from '@/constants'
import Typography from '@/components/Typography/Typography'

const aboveTheFoldPaddingTop = 80
const globeCutPartSize = 70
const backgroundImageRight = (1440 - 1148) / 2 + globeCutPartSize

export const AboveTheFoldWrapper = styled(SectionWrapper)<{ noPadding?: boolean }>`
  background-color: ${({ theme }) => theme.pageTheme?.light || theme.sectionTheme?.background};
  overflow: hidden;
  position: relative;

  ${({ noPadding }) => noPadding && css`
    ${Container} {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  `}
`

export const BackgroundImageWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
    position: absolute;
    right: 0;
    top: -${aboveTheFoldPaddingTop}px;
    left: 0;
    height: 100%;

    & > * {
      position: absolute !important;
      right: -${backgroundImageRight}px;
    }
  }
`

export const ContentWrapper = styled.div<{
  isCenterAlign?: boolean
  isFullWidth?: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  flex-wrap: wrap;

  ${({ isCenterAlign }) => isCenterAlign && css`
    text-align: center;
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 554px);
    grid-column-gap: 40px;

    ${({ isFullWidth }) => isFullWidth && css`
      display: block;
    `}
  }
`

export const LeftContainer = styled.div``

export const ProductName = styled.h2<{ isCenterAlign: boolean }>`
  ${styledText[TextStyles['Large Paragraph Text']]}
  margin-top: 0;
  text-align: center;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.pageTheme ? theme.colors.darkBlue : theme.sectionTheme?.text};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes[21]};
    line-height: ${({ theme }) => theme.lineHeight[29]};
    margin-bottom: 20px;
  }

  ${({ isCenterAlign, theme }) => isCenterAlign && `
    font-size: ${theme.fontSizes[16]} !important;
    line-height: 24px !important;
    margin-bottom: 8px !important;
    color: ${theme.colors.orange};
    text-align: center !important;
    font-weight: ${theme.fontWeight.bold};
  `}
`

export const HeaderWrapper = styled.div<{ isDesktop: boolean }>`
  display: ${({ isDesktop }) => isDesktop ? 'none' : 'block'};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: ${({ isDesktop }) => isDesktop ? 'block' : 'none'};
  }
`

export const SubCopy = styled(Typography).attrs({
  variant: TextStyles['Large Paragraph Text'],
})<{ isCenterAlign: boolean }>`
  color: ${({ theme }) => theme.pageTheme ? theme.colors.black01 : theme.sectionTheme?.subText};
  text-align: center;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Subheading']]}
    ${({ isCenterAlign }) => !isCenterAlign && `
      max-width: 560px;
      text-align: left;
    `}
  }
`

export const ButtonsWrapper = styled.div<{ buttonLength: number }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  text-align: center;

  ${({ buttonLength }) => buttonLength > 1 && `
    > * {
      flex: 1;
    }
  `}

  > *:not(:last-child) {
    margin-right: 30px;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    justify-content: flex-start;
    margin-top: 50px;

    > *:not(:last-child) {
      margin-right: 30px;
    }

    > * {
      flex: unset;
    }
  }
`

export const CustomHeroImageWrapper = styled(HeroImageWrapper)`
  display: flex;
  align-items: center;

  > img {
    height: unset;
    max-height: 100%;
    object-fit: cover;
  }

  > .dotlottie-container {
    width: 100%;
    height: 100%;
  }

  > .hero-image-with-animation {
    transform: unset;
  }
`
