import sample from './sample.utils'
import renderHTML from './renderHTML.utils'
import parseCookie from './parseCookie.utils'
import {
  getCurrentYear,
  sortDatesByClosest,
  sortDatesBy,
  convertISOtoUTC,
} from './dateTime.utils'
import { isAbsoluteUrl, isOpenInNewTab } from './url.utils'
import { getSectionTheme } from './theme.utils'
import { excludeEmptyItem, excludeUndefinedKey } from './array.utils'
import { formatLanguageData } from './locale.utils'
import {
  checkIsPreviewModeRequest,
  getPageSlug,
  checkIsArticlePageApp,
  checkIsCategoryPageApp,
  checkIsEventPageApp,
  getCategoryPageId,
  getCachePageSlugFromUrl,
  checkIsResourcesPageApp,
  getFormattedPageSlug,
  checkIsIndividualGlossaryPage,
} from './page.utils'
import documentToReactComponentsWithBr from './documentToReactComponentsWithBr.utils'
import { 
  pushEvent, 
  resetGTMDataLayer, 
  sendLinkedInAdsId 
} from './tracking.utils'
import { debounce } from './debounce.utils'
import {
  GTMButtonClick,
  GTMFormSubmission,
  GTMContactFormSubmission,
  GTMLinkedInAdsIdSubmission
} from './GTM'
import { checkIsErrorPage } from './errorPage'
import {
  isCali,
  isEU,
  fetchGeoAPI
} from './geo.utils'
import {
  getCookieBannerClosed,
  setCookieBannerClosed,
  setHasVisited,
  showCookieBannerPopup,
} from './localStorage.utils'
import {
  truncateTextWithEllipsis,
  tagGenerator,
  capitalize,
  getTextLevel,
} from './text.utils'
import {
  getLatestArticles,
  getLatestTopicTagRelatedArticles,
} from './category.utils'
import {
  headerNavigationDataModify,
  footerDataModify,
  fullPageDataModify,
  stickerBannerDataModify,
  articleDataModify,
  glossaryDataModify,
  categoryDataModify,
  thumbnailDataModify,
  relatedThumbnailDataModify,
  popUpDataModify,
} from './graphqlDataModify.utils'
import { sendSentryError } from './error.utils'
import { isVideo, isImage, isLottie } from './asset.utils'
import renderSiteMap, { constructSitemapItem } from "./sitemap.utils";
import { articlesRssXml, convertToRssData } from "./rssDataModify.utils";

export {
  sample,
  renderHTML,
  parseCookie,
  getPageSlug,
  checkIsArticlePageApp,
  checkIsCategoryPageApp,
  getCategoryPageId,
  getCachePageSlugFromUrl,
  getCurrentYear,
  getSectionTheme,
  excludeEmptyItem,
  excludeUndefinedKey,
  formatLanguageData,
  checkIsPreviewModeRequest,
  documentToReactComponentsWithBr,
  debounce,
  pushEvent,
  resetGTMDataLayer,
  sendLinkedInAdsId,
  GTMButtonClick,
  GTMFormSubmission,
  GTMContactFormSubmission,
  GTMLinkedInAdsIdSubmission,
  checkIsErrorPage,
  isCali,
  isEU,
  fetchGeoAPI,
  getCookieBannerClosed,
  setCookieBannerClosed,
  setHasVisited,
  showCookieBannerPopup,
  isAbsoluteUrl,
  isOpenInNewTab,
  truncateTextWithEllipsis,
  tagGenerator,
  capitalize,
  getTextLevel,
  getLatestArticles,
  getLatestTopicTagRelatedArticles,
  headerNavigationDataModify,
  footerDataModify,
  fullPageDataModify,
  stickerBannerDataModify,
  articleDataModify,
  glossaryDataModify,
  categoryDataModify,
  thumbnailDataModify,
  relatedThumbnailDataModify,
  popUpDataModify,
  sendSentryError,
  sortDatesByClosest,
  convertISOtoUTC,
  sortDatesBy,
  checkIsEventPageApp,
  checkIsResourcesPageApp,
  getFormattedPageSlug,
  isVideo,
  isImage,
  isLottie,
  articlesRssXml,
  convertToRssData,
  checkIsIndividualGlossaryPage,
  renderSiteMap,
  constructSitemapItem
}
export { modifyUrlQuery } from './path.utils'
export { default as minifyQuery } from './minifyQuery.utils'
export { 
  modifyEventsData, 
  resourcesPageSectionListModify, 
  modifyResourcesData 
} from './event.utils'
export { checkIsFromEmailDomainBlocked } from './form.utils'
export { getMatchingPopUps } from './popUp.utils'
export * from './env.utils'
export * from './vsec.utils'